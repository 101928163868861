import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const GreenButton = ({
  iconSrc,
  buttonText,
  width,
  height,
  fontSize,
  lineHeight,
  margin,
  marginLeft,
  marginTop,
  onClick,
  to,
  disabled,
  backgroundColor,
  backgroundColorHover,
  borderColor,
  textColor,
}) => {
  const nav = useNavigate();

  return (
    <Button
      onClick={(event) => {
        event.preventDefault();
        if (!disabled) {
          // Only perform actions if the button is not disabled
          if (to) {
            nav(to);
          }
          if (onClick) {
            onClick(event);
          }
        }
      }}
      variant="contained"
      sx={{
        backgroundColor: backgroundColor,
        padding: "12px 24px",
        borderRadius: "8px",
        gap: "8px",
        "&:hover": {
          backgroundColor: backgroundColorHover,
        },
        width,
        height,
        margin,
        marginLeft,
        marginTop,
        border: `2px solid ${borderColor}`,
        marginRight: "36px",
      }}
      startIcon={
        iconSrc
          ? // <img
            //   src={iconSrc}
            //   alt="Icon"
            //   style={{
            //     width: '20px',
            //     height: '20px',
            //   }}
            // />
            iconSrc
          : null
      }
      disabled={disabled} // Set the disabled state of the button
    >
      <h1
        style={{
          fontFamily: "Poppins, sans-serif",
          fontSize: `${fontSize}px`,
          fontWeight: 700,
          lineHeight: `${lineHeight}px`,
          letterSpacing: "-0.005em",
          textAlign: "left",
          textTransform: "none",
          color: textColor,
          whiteSpace: "nowrap",
        }}
      >
        {buttonText}
      </h1>
    </Button>
  );
};

GreenButton.propTypes = {
  iconSrc: PropTypes.object,
  buttonText: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.number,
  margin: PropTypes.string,
  marginLeft: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  marginTop: PropTypes.string,
  disabled: PropTypes.bool,
  borderColor: PropTypes.string,
  backgroundColorHover: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  textColor: PropTypes.string,
};

export default GreenButton;
