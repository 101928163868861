import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Green600, PrimaryGreen, White400 } from '../consts/colors';

const GreenButtonED = ({ iconSrc, buttonText, width, height, fontSize, lineHeight, margin, marginLeft, type, onClick, disabled }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick} 
      variant="contained"
      sx={{
        backgroundColor: disabled ? PrimaryGreen : Green600,
        width: width,
        minWidth: width,
        height: height,
        minHeight: height,
        padding: '12px 24px',
        borderRadius: '8px',
        gap: '8px',
        opacity: disabled ? 0.8 : 1,
        '&:hover': {
          backgroundColor: PrimaryGreen,
        },
      }}
    >
      <h1
        style={{
          fontFamily: 'Poppins, sans-serif',
          fontSize: fontSize,
          fontWeight: 700,
          lineHeight: lineHeight,
          letterSpacing: '-0.005em',
          textAlign: 'left',
          textTransform: 'none',
          color: White400,
        }}
      >
        {buttonText}
      </h1>
      <img
        src={iconSrc}
        alt="Icon"
        style={{
          width: '20px',
          height: '20px',
        }}
      />
    </Button>
  );
};

GreenButtonED.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.number,
  margin: PropTypes.string,
  marginLeft: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool, 
};

export default GreenButtonED;
