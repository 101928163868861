import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { Autocomplete, Box, LinearProgress, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosFetch from "../../api/Axios";
import { fetchProjectsData } from "../../components/listProjectCards/getProjects";
import WrapperPage from "../../components/WrapperPage";
import { getItem, setItem, useLocalStorage } from "../../utils/LocalStorageUtils";
import { columns, emptyFilters, NoRowsText } from "./constants";

export default function ActionItems({ sidebarWidth }) {
  const [actionItems, setActionItems] = useState([]);
  const [filters, setFilters] = useState(emptyFilters);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useLocalStorage(
    "actionItemsPaginationV2",
    {
      page: 0,
      pageSize: 100,
    },
  );
  const [sortModel, setSortModel] = useState();
  const [totalActionItems, setTotalActionItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const searchRef = useRef();
  const fetchHashRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    fetchHashRef.current = JSON.stringify({
      filters,
      paginationModel,
      sortModel,
    });
    const lsFilters = getItem("actionItemsFilter");
    if (lsFilters && lsFilters !== "") {
      try {
        const filterObject = JSON.parse(lsFilters);
        if (hasFilters(filterObject)) {
          setFilters(filterObject);
          setSearchValue(filterObject.searchValue);
        } else {
          fetchActionItems().then((res) => {
            updateActionItems(res);
          });
        }
      } catch (err) {
        console.log(err);
        fetchActionItems().then((res) => {
          updateActionItems(res);
        });
      }
    } else {
      fetchActionItems().then((res) => {
        updateActionItems(res);
      });
    }
    fetchUsers()
      .then((usersList) => setUsers(usersList))
      .catch((err) => console.log(err));
    fetchProjectsData()
      .then((projectsList) => setProjects(projectsList))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const fetchHash = JSON.stringify({
      filters,
      paginationModel,
      sortModel,
    });
    if (fetchHash !== fetchHashRef.current) {
      fetchHashRef.current = fetchHash;
      setActionItems([]);
      fetchActionItems().then((res) => updateActionItems(res, projects, users));
    }
  }, [paginationModel, sortModel, filters]);

  const hasFilters = (filterObject) =>
    filterObject.searchValue !== "" ||
    !!filterObject.status ||
    !!filterObject.owner ||
    !!filterObject.project;

  const updateActionItems = (response) => {
    setTotalActionItems(response.total);
    setActionItems(response.actionItems);
    setIsLoading(false);
  };

  const handleSortModelChange = (sorting) => setSortModel(sorting[0]);

  const fetchActionItems = async () => {
    setIsLoading(true);
    let queryParams = { ...paginationModel };
    if (sortModel) {
      queryParams = { ...queryParams, ...sortModel };
    }
    Object.keys(filters).forEach((key) => {
      if (!!filters[key]) {
        queryParams = { ...queryParams, [key]: filters[key] };
      }
    });
    return axiosFetch({
      method: "GET",
      params: queryParams,
      url: process.env.REACT_APP_API_PORT + "action_items",
    });
  };

  const fetchUsers = () =>
    axiosFetch({
      method: "GET",
      url: process.env.REACT_APP_API_PORT + "users?limit=all",
    });

  const getUserOption = (users, userId) => {
    const user = users.find((u) => u.user_id === userId);
    if (!user) {
      return null;
    }
    return { label: `${user.first_name} ${user.last_name}`, id: user.user_id };
  };

  const handleSearchChange = (ev) => {
    setSearchValue(ev.target.value);
    if (!!searchRef.current) {
      clearTimeout(searchRef.current);
    }
    searchRef.current = setTimeout(() => {
      const updateFilters = { ...filters, searchValue: ev.target.value };
      setFilters(updateFilters);
      setItem("actionItemsFilter", JSON.stringify(updateFilters));
      searchRef.current = undefined;
    }, 500);
  };

  const handleProjectChange = (ev, option) => {
    const updateFilters = { ...filters, project: option?.id || "" };
    setFilters(updateFilters);
    setItem("actionItemsFilter", JSON.stringify(updateFilters));
  };

  const handleOwnerChange = (ev, option) => {
    const updateFilters = { ...filters, owner: option?.id || "" };
    setFilters(updateFilters);
    setItem("actionItemsFilter", JSON.stringify(updateFilters));
  };

  const handleStatusChange = (ev, option) => {
    const updateFilters = { ...filters, status: option?.label || "" };
    setFilters(updateFilters);
    setItem("actionItemsFilter", JSON.stringify(updateFilters));
  };

  const projectsOptions = projects.map((p) => ({
    label: p.name,
    id: p.project_id,
  }));
  const usersOptions = users.map((u) => ({
    label: `${u.first_name} ${u.last_name}`,
    id: u.user_id,
  }));
  const statusOptions = [{ label: "Open" }, { label: "Closed" }];

  return (
    <WrapperPage
      title="Action Items"
      icon={<EventAvailableIcon style={{ fontSize: 32 }} />}
      sidebarWidth={sidebarWidth}
    >
      <Box display="flex" flexDirection="row" gap={4} marginTop={4}>
        <TextField
          size="small"
          style={{ width: 250 }}
          value={searchValue}
          onChange={handleSearchChange}
          placeholder={"Search action item..."}
          inputProps={{ type: "search" }}
        />
        <Autocomplete
          size="small"
          style={{ width: 250 }}
          value={projectsOptions.find((p) => p.id === filters.project) ?? null}
          renderInput={(params) => <TextField {...params} label="Project" />}
          options={projectsOptions}
          onChange={handleProjectChange}
        />
        <Autocomplete
          size="small"
          style={{ width: 250 }}
          value={getUserOption(users, filters.owner)}
          renderInput={(params) => <TextField {...params} label="Owner" />}
          options={usersOptions}
          onChange={handleOwnerChange}
        />
        <Autocomplete
          size="small"
          style={{ width: 150 }}
          value={filters.status ? { label: filters.status } : null}
          renderInput={(params) => <TextField {...params} label="Status" />}
          options={statusOptions}
          onChange={handleStatusChange}
        />
      </Box>
      <DataGrid
        loading={isLoading}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: () => NoRowsText,
        }}
        autoHeight
        columns={columns}
        rows={actionItems}
        getRowId={(a) => a.id}
        style={{ marginTop: 32 }}
        disableColumnMenu
        rowCount={+totalActionItems}
        pageSizeOptions={[5, 10, 25, { value: 100, label: "All" }]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        sx={{
          boxShadow: 2,
          border: "none",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        onRowClick={(params) =>
          navigate(`/see-details-project/${params.row.project_id}`)
        }
      />
    </WrapperPage>
  );
}
