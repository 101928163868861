import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { LineChartStyle } from "./LineChartStyle";
import { formatDate } from "../../utils/dateUtils";

const CustomDot = ({
  cx,
  cy,
  payload,
  active,
  canEditProject,
  canReviewProject,
  dotScope,
}) => {
  const navigate = useNavigate();
  const status = payload["status"];
  const review_id = payload["review_id"];
  let dotColor = "#8884d8";

  switch (dotScope) {
    case "Overall Score": {
      dotColor = status.toLowerCase();
      if (active) {
        dotColor = dotColor;
      }

      break;
    }
    case "Risk Level": {
      if (payload["risk_level"] === 1) {
        dotColor = "Yellow";
      } else if (payload["risk_level"] === 2) {
        dotColor = "Red";
      } else {
        dotColor = "Green";
      }

      break;
    }

    case "Needs Discussion": {
      if (payload["needs_attention"]) {
        dotColor = "Red";
      } else {
        dotColor = "Green";
      }
      break;
    }
  }

  const handleDotClick = () => {
    navigate(`/view-review/${review_id}`);
  };

  return (
    <circle
      cx={cx}
      cy={cy}
      r={6}
      fill={dotColor}
      onClick={canEditProject || canReviewProject ? handleDotClick : null}
      style={{ cursor: "pointer" }}
    />
  );
};

export const RenderLineChart = memo(
  ({ data, canEditProject, canReviewProject }) => {
    const reverseData = data.slice(0).reverse();
    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={reverseData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="created_date" tickFormatter={formatDate} />
          <YAxis domain={[0, 6]} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line
            type="monotone"
            dataKey="Overall Score"
            stroke="#8884d8"
            dot={
              <CustomDot
                dataKey="Overall Score"
                active={true}
                canEditProject={canEditProject}
                canReviewProject={canReviewProject}
                dotScope="Overall Score"
              />
            }
            activeDot={
              <CustomDot
                dataKey="Overall Score"
                active={true}
                canEditProject={canEditProject}
                canReviewProject={canReviewProject}
                dotScope="Overall Score"
              />
            }
            key={Math.random()} //forteaza renderul sa continuie ca sa mearga animatia si bullets :) ---work around for known issue on library
          />
          <Line
            type="monotone"
            dataKey="risk_level"
            name="Risk Level"
            stroke="#ffe86b"
            dot={
              <CustomDot
                dataKey="risk_level"
                active={true}
                canEditProject={canEditProject}
                canReviewProject={canReviewProject}
                dotScope="Risk Level"
              />
            }
            activeDot={
              <CustomDot
                dataKey="risk_level"
                active={true}
                canEditProject={canEditProject}
                canReviewProject={canReviewProject}
                dotScope="Risk Level"
              />
            }
            key={Math.random()} //forteaza renderul sa continuie ca sa mearga animatia si bullets :) ---work around for known issue on library
          />
          <Line
            colorInterpolation={"red"}
            type="monotone"
            dataKey="needs_attention"
            name="Needs Discussion"
            stroke="#477C34"
            dot={
              <CustomDot
                dataKey="needs_attention"
                active={true}
                canEditProject={canEditProject}
                canReviewProject={canReviewProject}
                dotScope="Needs Discussion"
              />
            }
            activeDot={
              <CustomDot
                dataKey="needs_attention"
                active={true}
                canEditProject={canEditProject}
                canReviewProject={canReviewProject}
                dotScope="Needs Discussion"
              />
            }
            key={Math.random()} //forteaza renderul sa continuie ca sa mearga animatia si bullets :) ---work around for known issue on library
          />
        </LineChart>
      </ResponsiveContainer>
    );
  },
);

export const CustomTooltip = (props) => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    const dataKeys = Object.keys(payload[0].payload).filter(
      (key) =>
        key !== "created_date" &&
        key !== "manager_comment" &&
        key !== "project_id" &&
        key !== "review_id" &&
        key !== "status" &&
        key !== "user_id" &&
        key !== "client_partner_comment" &&
        key !== "needs_attention" &&
        key !== "risk_level",
    );
    return (
      <Box style={LineChartStyle.customTool}>
        <Box style={LineChartStyle.customValues}>
          {dataKeys.map((key) => (
            <Typography key={key} style={LineChartStyle.customValue}>
              {`${key}: ${Number(payload[0].payload[key]).toFixed(3)}`}
            </Typography>
          ))}
        </Box>
      </Box>
    );
  }
  return null;
};
