import { Black400 } from "../../consts/colors";

export const LineChartStyle = {
  customTool: {
    backgroundColor: "white",
    border: "1px solid #ccc",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  },
  customRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  customValues: {
    display: "flex",
    flexDirection: "column",
  },
  customValue: {
    marginTop: "2px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18.2px",
    color: Black400,
  },
};
