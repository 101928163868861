import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { LineChartStyle } from "./LineChartStyle";
import { formatDate } from "../../utils/dateUtils";

const CustomDot = ({ cx, cy }) => {
  let dotColor = "#8884d8";
  return (
    <circle
      cx={cx}
      cy={cy}
      r={6}
      fill={dotColor}
      style={{ cursor: "pointer" }}
    />
  );
};

export const GrossMarginLineChart = memo(({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="submitted_at" tickFormatter={formatDate} />
        <YAxis domain={[0, 100]} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          dataKey="gross_margin"
          stroke="#8884d8"
          name="Gross Margin"
          dot={<CustomDot />}
          activeDot={<CustomDot />}
          key={Math.random()} //forteaza renderul sa continuie ca sa mearga animatia si bullets :) ---work around for known issue on library
        />
      </LineChart>
    </ResponsiveContainer>
  );
});

function formatToolTip(key, value) {
  switch (key) {
    case "gross_margin":
      return `GM : ${new Number(value).toFixed(2)}%`;
    case "submitted_at":
      return `Submitted At: ${formatDate(value)}`;
    default:
      return `${key}: ${value}`;
  }
}

export const CustomTooltip = (props) => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    const dataKeys = Object.keys(payload[0].payload);
    return (
      <Box style={LineChartStyle.customTool}>
        <Box style={LineChartStyle.customValues}>
          {dataKeys.map((key) => (
            <Typography key={key} style={LineChartStyle.customValue}>
              {formatToolTip(key, payload[0].payload[key])}
            </Typography>
          ))}
        </Box>
      </Box>
    );
  }
  return null;
};
