import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import { TableHead } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { ArrowBack, ArrowForward } from "../../assets/icons";
import styles, { StyledTableCell } from "./style";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? <ArrowForward /> : <ArrowBack />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <ArrowBack /> : <ArrowForward />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function CustomTable({
  headers,
  rows,
  isMarginTopRequired,
  goToReviewPageNeeded,
  paginationModel,
  onPaginationModelChanged,
}) {
  const [page, setPage] = React.useState(paginationModel?.page || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    paginationModel?.pageSize || 5,
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (onPaginationModelChanged)
      onPaginationModelChanged({ ...paginationModel, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    let newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (onPaginationModelChanged)
      onPaginationModelChanged({ pageSize: newRowsPerPage, page: 0 });
  };

  useEffect(() => {
    if (page > 0 && rows.length < rowsPerPage) {
      setPage(0);
      if (onPaginationModelChanged)
        onPaginationModelChanged({ ...paginationModel, page: 0 });
    }
  }, [rows]);

  const paginatedRows =
    paginationModel.pageSize === -1
      ? rows
      : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box
      sx={
        isMarginTopRequired
          ? [{ marginTop: "30px", width: "98.5%" }]
          : [{ marginTop: "0px", width: "98.5%" }]
      }
    >
      <Table
        sx={{
          borderCollapse: "separate",
          borderSpacing: "0 5px",
          border: "none",
          maxWidth: "100%",
          overflowX: "auto",
        }}
        aria-label="custom pagination table"
      >
        <TableHead>
          <TableRow style={{ ...styles.row, height: "44px" }}>
            {headers.map((header) => (
              <StyledTableCell
                style={{ ...styles.header, width: header.width }}
                key={`${header.key}`}
              >
                {header.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows.map((row, rowIndex) => (
            <TableRow
              key={row.key ?? `${page}${rowIndex}`}
              style={{
                ...styles.row,
                height: "44px",
                cursor: goToReviewPageNeeded ? "pointer" : "default",
              }}
              onClick={
                goToReviewPageNeeded
                  ? () =>
                      (window.location.href = `/view-review/${row["review_id"]}`)
                  : undefined
              }
            >
              {headers.map((header) => (
                <StyledTableCell
                  key={`${header.key}_${rowIndex}`}
                  component="th"
                  scope="row"
                  style={{
                    ...styles.data,
                    width: header.width,
                  }}
                >
                  {row[header.key]}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <StyledTableCell colSpan={headers.length} />
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              style={styles.footer}
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={headers.length}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}

CustomTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMarginTopRequired: PropTypes.bool,
  goToReviewPageNeeded: PropTypes.bool,
};

export default CustomTable;
