import React from "react";
import { Box } from "@mui/material";
import Header from "./header/Header";
import { getItem } from "../utils/LocalStorageUtils";
import { useEffect, useState } from "react";
import "./sidebar/Sidebar.css";

export default function WrapperPage({ children, title, icon, sidebarWidth }) {
  const [profile, setProfile] = useState({});

  useEffect(() => {
    setProfile(getItem("profile"));
  }, []);

  return (
    <Box
      sx={{
        marginLeft: `${sidebarWidth + 30}px`,
        minHeight: "100vh",
        // minWidth: "1200px",
        marginTop: "36px",
        transition: "margin-left 1s hard",
      }}
    >
      <Header
        profilePicture={profile?.picture}
        surname={profile?.given_name}
        title={title}
        icon={icon}
      />
      <Box sx={{ paddingBottom: "30px", justifyContent: "center" }}>
        {children}
      </Box>
    </Box>
  );
}
