import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CurrentUserContext } from '../../App';
import {
    ArchiveProjectIcon,
    DashboardIcon,
    LogoutIcon,
    ProjectsIcon,
    QuestionsIcon,
    SettingsIcon,
    UsersIcon,
} from '../../assets/icons/index.js';
import { Green600, White400, White800 } from '../../consts/colors.js';
import { USER_ROLES } from '../../consts/constants.js';
import { getItem, setItem } from '../../utils/LocalStorageUtils.js';
import { logOut } from '../../utils/userUtils.js';
import { ProjectCardStyle } from '../projectsCard/ProjectCardStyles.js';
import { sidebarButtonStyle } from './SidebarButtonStyle';

const buttons = [
    {
        id: 1,
        label: 'Dashboard',
        icon: <DashboardIcon />,
        link: '/dashboard',
    },
    {
        id: 2,
        label: 'Projects',
        icon: <ProjectsIcon />,
        link: '/projects',
    },
    {
        id: 3,
        label: 'Users',
        icon: <UsersIcon />,
        link: '/users',
        requiredRole: USER_ROLES.ADMIN,
    },
    {
        id: 4,
        label: 'Action Items',
        icon: <EventAvailableIcon style={{ fontSize: 24 }} />,
        link: '/actionItems',
        requiredRole: USER_ROLES.ADMIN,
    },
    {
        id: 5,
        label: 'Questions',
        icon: <QuestionsIcon />,
        link: '/questions',
        requiredRole: USER_ROLES.ADMIN,
    },
    {
        label: 'Admin Settings',
        icon: <SettingsIcon />,
        link: '/settings',
        requiredRole: USER_ROLES.ADMIN,
    },
    {
        id: 6,
        label: 'Archived projects',
        icon: <ArchiveProjectIcon />,
        link: '/archived-projects',
        requiredRole: USER_ROLES.ADMIN,
    },
    {
        id: 7,
        label: 'Log Out',
        icon: <LogoutIcon />,
        link: '/login',
    },
];

const SidebarButton = ({ sidebarWidth }) => {
    const [activeButton, setActiveButton] = useState();
    const location = useLocation();
    const { currentUser } = useContext(CurrentUserContext);

    const handleButtonClick = (index) => {
        setActiveButton(index);
        // logOut button will be on the last position
        if (index === buttons.length - 1) {
            logOut();
        }
    };

    useEffect(() => {
        const activeButton = getItem('activeButton');
        if (activeButton !== undefined && activeButton !== null) {
            setActiveButton(activeButton);
        }
    }, []);

    useEffect(() => {
        const matchingButton = buttons.findIndex((button) => button.link === location.pathname);
        setActiveButton(matchingButton);
    }, [location]);

    useLayoutEffect(() => {
        setItem('activeButton', activeButton);
    }, [activeButton]);

    return (
        <>
            {buttons.map((button, index) => {
                const active = location.pathname === button.link || activeButton === index;
                const showButton = !button.requiredRole || (currentUser && button.requiredRole === currentUser.role);
                // Add the key prop only when showButton is true
                return showButton ? (
                    <Box key={button.id} sx={sidebarButtonStyle.container}>
                        <Link to={button.link}>
                            <Tooltip
                                placement="right"
                                title={
                                    sidebarWidth < 150 ? <Typography sx={ProjectCardStyle.tooltipTitle}>{button.label}</Typography> : null
                                }
                            >
                                <Button
                                    startIcon={button.icon}
                                    disableRipple
                                    sx={{
                                        ...sidebarButtonStyle.button,
                                        color: active ? Green600 : White800,
                                        fontWeight: active ? 'medium' : 'regular',
                                        backgroundColor: active ? White400 : Green600,
                                        width: `${sidebarWidth - 68}px`,
                                    }}
                                    onClick={() => handleButtonClick(index)}
                                >
                                    {sidebarWidth >= 150 ? button.label : null}
                                </Button>
                            </Tooltip>
                        </Link>
                    </Box>
                ) : null;
            })}
        </>
    );
};

export default SidebarButton;
