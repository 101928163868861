import React, { createContext, useEffect, useState } from 'react';
import { Outlet, Route, Routes, unstable_HistoryRouter as Router } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import SideBar from './components/sidebar/SideBar';
import { White400 } from './consts/colors';
import { SideBarWidth } from './consts/constants';
import ActionItems from './pages/ActionItems/ActionItems';
import AddQuestions from './pages/AddQuestions/AddQuestions';
import ArchivedProjectsPage from './pages/ArchivedProjects/ArchivedProjectsPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import EditQuestionsPage from './pages/EditQuestion/EditQuestionPage';
import EditUserPage from './pages/EditUser/EditUserPage';
import Login from './pages/Login/Login';
import NewProjectPage from './pages/NewProject/NewProjectPage';
import NewUserPage from './pages/NewUser/NewUserPage';
import ProjectReviewPage from './pages/ProjectReview/ProjectReview';
import ProjectsPage from './pages/Projects/ProjectsPage';
import QuestionsPage from './pages/Questions/QuestionsPage';
import ReviewHistoryPage from './pages/ReviewHistory/ReviewHistory';
import SeeProjectDetails from './pages/SeeProjectDetails/SeeProjectDetails';
import SettingsPage from './pages/Settings/SettingsPage';
import UsersPage from './pages/Users/UsersPage';
import ViewEditPage from './pages/ViewEdit/ViewEditPage';
import ViewReviewPage from './pages/ViewReview/ViewReviewPage';
import { history } from './utils/history';
import { getItem, setItem } from './utils/LocalStorageUtils';

export const CurrentUserContext = createContext({});

function App() {
    const [isResizing, setIsResizing] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(() => {
        const storedWidth = getItem('sidebarWidth');
        return storedWidth || SideBarWidth;
    });

    const handleMouseDown = () => {
        setIsResizing(true);
    };

    const handleMouseUp = () => {
        setIsResizing(false);
    };

    const handleMouseMove = (e) => {
        if (isResizing) {
            const newWidth = Math.min(Math.max(e.clientX, 60), 420);
            document.body.style.cursor = 'ew-resize';
            setSidebarWidth(newWidth);
            // Store the width in local storage
            setItem('sidebarWidth', newWidth);
        }
    };
    useEffect(() => {
        if (isResizing) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.body.style.cursor = 'auto';
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.body.style.cursor = 'auto';
        };
    }, [isResizing]);

    const [currentUser, setCurrentUser] = useState(null);

    document.body.style.backgroundColor = White400;

    useEffect(() => {
        document.body.style.overflow = 'scroll';
        setCurrentUser(getItem('currentUser'));

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
            <Router history={history}>
                <Routes>
                    <Route
                        element={
                            <div>
                                <Outlet />
                                <SideBar
                                    isResizing={isResizing}
                                    handleMouseDown={handleMouseDown}
                                    sidebarWidth={sidebarWidth}
                                    setSidebarWidth={setSidebarWidth}
                                />
                            </div>
                        }
                    >
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <DashboardPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <DashboardPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/projects"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <ProjectsPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/actionItems"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <ActionItems sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/questions"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <QuestionsPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <UsersPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/settings"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <SettingsPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/newproject"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <NewProjectPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/view-edit/:itemId"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <ViewEditPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/add-questions"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <AddQuestions sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/new-user"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <NewUserPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/edit-user/:userId"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <EditUserPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/edit-question/:questionId"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <EditQuestionsPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/review-project/:itemId"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <ProjectReviewPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/review-history/:itemId"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <ReviewHistoryPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/see-details-project/:itemId"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <SeeProjectDetails sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/view-review/:itemId"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <ViewReviewPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/archived-projects"
                            element={
                                <ProtectedRoute sidebarWidth={sidebarWidth}>
                                    <ArchivedProjectsPage sidebarWidth={sidebarWidth} />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route path="/login" element={<Login />} />
                </Routes>
            </Router>
        </CurrentUserContext.Provider>
    );
}

export default App;
