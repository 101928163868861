import { Edit, History, RemoveRedEye } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, LinearProgress, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArchiveProjectIcon } from '../../assets/icons';
import { Green300, RedAlert, White100, Yellow400 } from '../../consts/colors';
import { getItem, setItem, useLocalStorage } from '../../utils/LocalStorageUtils';
import { ProjectCardStyle } from '../projectsCard/ProjectCardStyles';
import PromptUser from '../promt/PromptUser';

const maxReviewTime = process.env.REACT_APP_REVIEW;
const RED_DOT = (
    <div
        style={{
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: RedAlert,
            marginRight: '5px',
        }}
    />
);

const GREEN_DOT = (
    <div
        style={{
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: Green300,
            marginRight: '5px',
        }}
    />
);

const YELLOW_DOT = (
    <div
        style={{
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: Yellow400,
            marginRight: '5px',
        }}
    />
);

export default function TableProjectsComponent({ projects, isLoading, handleArchiveProject, currentUser }) {
    const [confirmArchive, setConfirmArchive] = useState(false);
    const [deleteProjectId, setDeleteProjectId] = useState();
    const [paginationModel, setPaginationModel] = useLocalStorage('projectsPaginationV2', {
        pageSize: 100,
        page: 0,
    });
    const navigate = useNavigate();

    useEffect(() => {
        const sortModel = getItem('projectsSortModel');
        if (sortModel) {
            setSortModel(sortModel);
        }
    }, []);

    const getActions = (params) => {
        let actions = [
            <GridActionsCellItem
                icon={<RemoveRedEye />}
                label="Review project"
                onClick={() => navigate(`/review-project/${params.row.project_id}`)}
                showInMenu
            />,
            <GridActionsCellItem
                icon={<History />}
                label="View review history"
                onClick={() => navigate(`/review-history/${params.row.project_id}`)}
                showInMenu
            />,
        ];

        if (currentUser?.role === 'Admin') {
            const adminActions = [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="Edit Project"
                    onClick={() => navigate(`/view-edit/${params.row.project_id}`)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Archive"
                    onClick={() => {
                        setDeleteProjectId(params.row.project_id);
                        setConfirmArchive(true);
                    }}
                    showInMenu
                />,
            ];
            actions = [...actions, ...adminActions];
        }
        return actions;
    };

    let columns = [
        {
            headerName: 'Name',
            field: 'name',
            width: 200,
            sorting: true,
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.name} arrow>
                        {params.row.name}
                    </Tooltip>
                );
            },
        },
        {
            headerName: 'Risk',
            field: 'risk_level',
            width: 80,
            sorting: true,
            // align: 'center',
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                switch (params.row.risk_level) {
                    case 0:
                        return GREEN_DOT;
                    case 1:
                        return YELLOW_DOT;
                    case 2:
                        return RED_DOT;
                }
            },
        },
        {
            headerName: 'Needs discussion',
            field: 'needs_attention',
            width: 170,
            sorting: true,
            align: 'center',
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return params.row.needs_attention ? <>{RED_DOT}Yes</> : <>{GREEN_DOT}No</>;
            },
        },
        {
            headerName: 'Last Review',
            field: 'created_date',
            width: 150,
            sorting: true,
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                if (!params.row.created_date) {
                    return 'No reviews';
                }
                const time = moment().diff(moment(params.row.created_date), 'days');
                return (
                    <>
                        {time > maxReviewTime ? RED_DOT : GREEN_DOT}
                        {time} days ago
                    </>
                );
            },
        },
        {
            headerName: 'Responsible',
            field: 'id',
            width: 120,
            align: 'center',
            sorting: true,
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                const project = params.row;
                const text = (
                    <table>
                        <tbody>
                            <tr>
                                <td>MGR:</td>
                                <td>
                                    {project.ro_first_name} {project.ro_last_name}
                                </td>
                            </tr>
                            <tr>
                                <td>CP:</td>
                                <td>
                                    {project.cp_first_name} {project.cp_last_name}
                                </td>
                            </tr>
                            <tr>
                                <td>DoE:</td>
                                <td>
                                    {project.doe_first_name} {project.doe_last_name}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <Tooltip title={text} placement="right">
                        <VisibilityIcon />
                    </Tooltip>
                );
            },
        },
        {
            headerName: 'Score',
            field: 'overall_score',
            width: 100,
            sorting: true,
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                if (!params.row.overall_score) {
                    return '';
                }
                return (
                    <Box
                        onClick={(ev) => {
                            ev.stopPropagation();
                            navigate(`/view-review/${params.row.review_id}`);
                        }}
                        sx={{
                            ...ProjectCardStyle.overallScoreBox,
                            position: 'relative',
                            bottom: 0,
                            right: 0,
                            width: 50,
                        }}
                    >
                        <Typography sx={ProjectCardStyle.overallScore}>{params.row.overall_score}</Typography>
                    </Box>
                );
            },
        },
        {
            headerName: 'Manager Comment',
            field: 'manager_comment',
            width: 210,
            sorting: false,
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.manager_comment} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} arrow>
                        {params.row.manager_comment}
                    </Tooltip>
                );
            },
        },
        {
            headerName: 'Client Comment',
            field: 'client_partner_comment',
            width: 210,
            sorting: false,
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.client_partner_comment} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} arrow>
                        {params.row.client_partner_comment}
                    </Tooltip>
                );
            },
        },
        {
            headerName: 'Open Actions',
            field: 'has_open_actions',
            width: 120,
            sorting: false,
            align: 'center',
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return params.row.has_open_actions ? <>{RED_DOT}Yes</> : <>{GREEN_DOT}No</>;
            },
        },
        {
            headerName: 'Gross Margin',
            field: 'gross_margin',
            width: 140,
            sorting: false,
            align: 'center',
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return params.row.gross_margin ? <>{params.row.gross_margin}</> : <>Nothing yet</>;
            },
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            headerClassName: 'tableHeader',
            headerAlign: 'right',
            align: 'right',
            getActions: (params) => getActions(params),
        },
    ];

    // If the user is not admin, some columns are filtered
    if (currentUser?.role?.toLowerCase() !== 'admin') {
        const columnNamesOnlyForAdmins = ['Gross Margin'];
        columns = columns.filter((column) => !columnNamesOnlyForAdmins.includes(column.headerName));
    }

    const [sortModel, setSortModel] = useState([{ field: 'name', sort: 'asc' }]);

    const handleSorting = (sortModel) => {
        setSortModel(sortModel);
        setItem('projectsSortModel', sortModel);
    };

    console.log(projects.length);
    return (
        <>
            <DataGrid
                sortModel={sortModel}
                onSortModelChange={handleSorting}
                loading={isLoading}
                slots={{
                    loadingOverlay: LinearProgress,
                }}
                columns={columns}
                rows={projects}
                getRowId={(p) => p.project_id}
                style={{ marginTop: 32 }}
                disableColumnMenu
                pageSizeOptions={[5, 10, 25, { value: 100, label: 'All' }]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                sx={{
                    boxShadow: 2,
                    border: 'none',
                    '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main',
                    },
                    '.MuiDataGrid-row': {
                        cursor: 'pointer',
                    },
                }}
                onRowClick={(project) => navigate(`/see-details-project/${project.id}`)}
            />
            {confirmArchive && (
                <PromptUser
                    title="Confirm Archiving the Project"
                    actionName="Archive project"
                    action={handleArchiveProject}
                    actionId={deleteProjectId}
                    setPromptVisible={setConfirmArchive}
                    icon={<ArchiveProjectIcon width={'20px'} height={'20px'} color={White100} />}
                />
            )}
        </>
    );
}
