import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material'; 
import { LocalizationProvider } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import { Green600 } from '../consts/colors';

const customTheme = createTheme({
  palette: {
    primary: {
      main: Green600,
    },
  },
});

// Custom styles for the MobileDatePicker
const StyledMobileDatePicker = styled(MobileDatePicker)(({ theme }) => ({
  '& .MuiPickersDay-daySelected': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '50%',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
  },
}));

const CustomDatePicker = ({ label, value, onChange }) => {
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={customTheme}>
        <StyledMobileDatePicker
          label={label}
          value={value}
          onChange={handleDateChange}
          dateAdapter={AdapterDayjs}
          format="DD/MM/YYYY" 
          TextFieldComponent={TextField} 
          closeOnSelect={true}
          PopoverProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
          }}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;